import makerjs from 'makerjs'

addEventListener('message', event => {
  if (event.data) {
    postMessage({
      base: makerjs.exporter.toSVG(event.data, {
        layerOptions: {
          "model": { fill: 'RGBA(0,0,0,0.3', stroke: 'RGBA(0,0,0,0.3)' }
        }
      }),
      edit: makerjs.exporter.toSVG(event.data, {
        useSvgPathOnly: false
      })
    })
  }
});
